import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { kebabCase } from 'lodash';
import React from 'react';

import IntroText from '../components/intro-text';
import Layout from '../components/layout';
import MetaData from '../components/meta-data';
import NewsSection from '../components/news-section';

const NewsList = ({ data, pageContext }) => {
  const title = 'News';

  const posts = data.allMarkdownRemark.edges;

  const { currentPage, numPages, category } = pageContext;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;

  const mainUrl =
    '/news/' + (category === '*' ? '' : kebabCase(category) + '/');

  const prevPage =
    currentPage - 1 === 1
      ? mainUrl
      : mainUrl + 'seite/' + (currentPage - 1) + '/';
  const nextPage = mainUrl + 'seite/' + (currentPage + 1) + '/';

  const details =
    category === '*' ? null : (
      <ul className="entry-meta mt-20 mb-0">
        <li className="entry-category">
          <i className="fa fa-folder-open"></i>
          {category}
        </li>
      </ul>
    );

  return (
    <Layout path="/news/">
      <MetaData
        title={title + (category === '*' ? '' : ' - ' + category)}
        description="Auf dieser Seite findest du alle Neuigkeiten rund um den Coiffeur Cocoon."
      />

      <IntroText title={title} details={details}>
        Auf dieser Seite findest du alle Neuigkeiten rund um den Coiffeur
        Cocoon. Um dich laufend von neuen Ideen inspirieren zu lassen, folge
        auch unseren Social Media Profilen auf{' '}
        <a
          href={'https://www.instagram.com/coiffeurcocoon'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="no-wrap">
            <i className="fa fa-external-link"></i> Instagram
          </span>
        </a>
        ,{' '}
        <a
          href={'https://www.facebook.com/coiffeurcocoon'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="no-wrap">
            <i className="fa fa-external-link"></i> Facebook
          </span>
        </a>{' '}
        und{' '}
        <a
          href={'https://www.twitter.com/coiffeurcocoon'}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="no-wrap">
            <i className="fa fa-external-link"></i> Twitter
          </span>
        </a>
        .
      </IntroText>

      <NewsSection>
        {posts.map(({ node }) => (
          <article className="entry-item" key={node.fields.slug}>
            <div className="entry-img">
              <Link to={node.fields.slug}>
                <GatsbyImage
                  image={getImage(node.frontmatter.image)}
                  alt={node.frontmatter.title}
                />
              </Link>
            </div>

            <div className="entry-wrap">
              <div className="entry">
                <ul className="entry-meta">
                  <li className="entry-date">
                    <i className="fa fa-calendar-o"></i>
                    {node.frontmatter.date}
                  </li>
                  <li className="entry-category">
                    <i className="fa fa-folder-open"></i>
                    <Link
                      to={'/news/' + kebabCase(node.frontmatter.category) + '/'}
                    >
                      {node.frontmatter.category}
                    </Link>
                  </li>
                </ul>

                <h2 className="entry-title">
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </h2>

                <div className="entry-content">
                  <p>{node.frontmatter.description}</p>
                  <Link to={node.fields.slug} className="read-more">
                    Weiterlesen
                  </Link>
                </div>
              </div>
            </div>
          </article>
        ))}

        {numPages > 1 && (
          <nav className="pagination clearfix">
            {!isFirst && (
              <Link to={prevPage}>
                <i className="fa fa-angle-left"></i>
              </Link>
            )}

            {[...Array(numPages)].map((e, i) =>
              i + 1 === currentPage ? (
                <span className="page-numbers current" key={i + 1}>
                  {i + 1}
                </span>
              ) : i + 1 === 1 ? (
                <Link to={mainUrl} key={i + 1}>
                  {i + 1}
                </Link>
              ) : (
                <Link to={mainUrl + 'seite/' + (i + 1) + '/'} key={i + 1}>
                  {i + 1}
                </Link>
              )
            )}

            {!isLast && (
              <Link to={nextPage}>
                <i className="fa fa-angle-right"></i>
              </Link>
            )}
          </nav>
        )}
      </NewsSection>
    </Layout>
  );
};

export default NewsList;

export const pageQuery = graphql`
  query ($category: String!, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { glob: $category } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "DD. MMMM YYYY", locale: "de")
            category
            image {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
